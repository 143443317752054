import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { AppBar, Box, Hidden, IconButton, Toolbar, SvgIcon, Typography, makeStyles } from '@material-ui/core';
import LogoWhite from 'src/components/LogoWhite';
import type { Theme } from 'src/theme';
import useAuth from 'src/hooks/useAuth';
import { AuthContextValue } from 'src/contexts/FirebaseAuthContext';
import { Menu, ExitToApp, ArrowBack } from '@material-ui/icons';
import { items } from 'src/components/NavBar';

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
  },
  toolbar: {
    minHeight: 64,
  },
}));

const TopBar: FC<TopBarProps> = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { isAuthenticated, logout, user, seafarer } = useAuth() as AuthContextValue;

  const getPreviousItem = () => {
    const itemsToShow = items.filter((item) => seafarer && item.hide && !item.hide(seafarer));

    if (itemsToShow.length <= 1) {
      return null;
    }

    if (location.pathname === '/app/profile-info') {
      return {
        title: 'Profile Info',
        to: '/app/profile',
      };
    }
    if (location.pathname === '/app/sea-services') {
      return {
        title: 'Sea services',
        to: '/app/profile',
      };
    }
    if (location.pathname === '/app/documents') {
      return {
        title: 'Documents',
        to: '/app/profile',
      };
    }
    if (location.pathname !== '/app/dashboard') {
      const view = items.find((item) => item.href === location.pathname);
      return view && view.title ? { title: view.title, to: '/app/dashboard' } : null;
    }
    return null;
  };

  const menuItem = getPreviousItem();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        {isAuthenticated ? (
          <>
            <Hidden lgUp>
              {menuItem ? (
                <>
                  <IconButton color="inherit" onClick={() => history.push(menuItem.to)}>
                    <SvgIcon fontSize="medium">
                      <ArrowBack />
                    </SvgIcon>
                  </IconButton>
                  <Typography variant="h6">{menuItem.title}</Typography>
                </>
              ) : (
                <IconButton color="inherit" onClick={onMobileNavOpen}>
                  <SvgIcon fontSize="small">
                    <Menu />
                  </SvgIcon>
                </IconButton>
              )}
            </Hidden>
            {user && seafarer && seafarer.hasSeafairPool ? (
              <Hidden mdDown>
                <RouterLink to="/">
                  <LogoWhite />
                </RouterLink>
              </Hidden>
            ) : (
              <></>
            )}
          </>
        ) : (
          <RouterLink to="/">
            <LogoWhite />
          </RouterLink>
        )}
        <Box ml={2} flexGrow={1} />
        <Box ml={2}>
          {isAuthenticated ? (
            <Typography>
              Log out
              <IconButton color="inherit" onClick={logout}>
                <SvgIcon fontSize="small">
                  <ExitToApp />
                </SvgIcon>
              </IconButton>
            </Typography>
          ) : (
            <Typography>
              Log in
              <IconButton color="inherit" component={RouterLink} to="/login">
                <SvgIcon fontSize="small">
                  <ExitToApp />
                </SvgIcon>
              </IconButton>
            </Typography>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
