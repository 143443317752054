import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import LocationState from 'src/types/locationState';

interface GuestGuardV2Props {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardV2Props> = ({ children }) => {
  const { state, pathname } = useLocation<LocationState>();
  const { isAuthenticated, seafarer } = useAuth();
  const path = '/app/dashboard-2';
  const shipcoPortalPath = '/app/my-shipcos';

  if (pathname === '/claim-account-v2') {
    // TODO: Handle the navigation when the user is authenticated (refresh/back button)
    return <>{children}</>;
  }

  if (isAuthenticated) {
    if (seafarer && seafarer.hasSeafairPool === false) {
      return <Redirect to={shipcoPortalPath} />;
    }
    if (state && state.referrer && !state.referrer.includes(shipcoPortalPath)) {
      return <Redirect to={state.referrer} />;
    }
    return <Redirect to={path} />;
  }

  return <>{children}</>;
};

export default GuestGuard;
