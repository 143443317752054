import React from 'react';
import type { FC, ReactNode } from 'react';
import useAuth from 'src/hooks/useAuth';
import { AuthContextValue } from 'src/contexts/FirebaseAuthContext';

interface ExternalGuardProps {
  children?: ReactNode;
}

const ExternalGuard: FC<ExternalGuardProps> = ({ children }) => {
  const { isAuthenticated, logout } = useAuth() as AuthContextValue;

  if (isAuthenticated) {
    logout();
  }

  return <>{children}</>;
};

export default ExternalGuard;
