import { colors, responsiveFontSizes } from '@material-ui/core';
import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import type { Theme as MuiTheme } from '@material-ui/core/styles';
import type {
  Palette as MuiPalette,
  TypeBackground as MuiTypeBackground,
} from '@material-ui/core/styles/createPalette';
import typography from 'src/theme/typography';

interface TypeBackground extends MuiTypeBackground {
  default: string;
  paper: string;
}

interface Palette extends MuiPalette {
  background: TypeBackground;
}

export interface Theme extends MuiTheme {
  name: string;
  palette: Palette;
}

interface ThemeOptions {
  typography?: Record<string, unknown>;
  overrides?: Record<string, unknown>;
  palette?: Record<string, unknown>;
}

const baseOptions: ThemeOptions = {
  typography,
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: '1rem',
        fontWeight: 400,
      },
    },
    MuiCardHeader: {
      title: {
        fontSize: '16px',
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: colors.blueGrey[600],
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '14px',
      },
    },
  },
  palette: {
    primary: {
      main: '#5295EF',
      dark: '#001B2E',
      light: 'rgba(82, 149, 239, 0.4)',
    },
    secondary: {
      main: '#6F6F6F',
      dark: '#5B5B5B',
      light: '#BDBDBD',
    },
    warning: {
      main: '#FE842B',
      light: 'rgba(254, 132, 43, 0.4)',
    },
    error: {
      main: '#EF5252',
      light: 'rgba(239, 82, 82, 0.4)',
    },
    info: {
      main: '#5295EF',
      light: 'rgba(82, 149, 239, 0.4)',
    },
    background: {
      default: '#FFFFFF',
      paper: '#F6F6F6',
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  },
};

export const createTheme = (): Theme => {
  let theme = createMuiTheme(baseOptions);
  theme = responsiveFontSizes(theme);
  return theme as Theme;
};
