import React from 'react';
import ReactDOM from 'react-dom';
import { enableES5 } from 'immer';
import App from 'src/App';
import gtm from 'src/lib/gtm';
import { initializeSentry } from 'src/lib/sentry';
import initializeHotjar from 'src/lib/hotjar';
import * as serviceWorker from 'src/serviceWorker';
import 'src/extensions/dates';
import 'src/extensions/moments';

enableES5();
gtm.initialize();
initializeSentry();
initializeHotjar();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
