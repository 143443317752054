import { FormikValues } from 'formik';
import axios from 'src/lib/axios';
import camelize from 'camelize';
import { Placement } from 'src/models/placement';
import {
  AvailabilityStatus,
  Seafarer,
  SeafarerChild,
  SeafarerFamily,
  SeafarerLoginSerializer,
  SeafarerNextOfKin,
  SeafarerProfileCompletion,
  SeafarerVesselExperience,
} from 'src/models/seafarer';
import type { Utm } from 'src/models/utm';
import snakeize from 'snakeize';
import { getBrandType } from 'src/constants/brandTypes';
import { ApplicationFilters } from 'src/models/application';
import { Vacancy } from 'src/models/vacancy';

export const checkEmail = (email: string): Promise<void> => {
  return axios.get(`/seafarers/check?email=${email}`);
};

export const signup = (
  email: string,
  password: string,
  phone: string,
  utm?: Utm,
  claimed = 'CLAIMED',
  newRegistrationFlow = false,
) => {
  const data = {
    email,
    password,
    phone,
    utm,
    claimed,
    platform: getBrandType('id'),
    new_registration_flow: newRegistrationFlow,
  };
  return axios.post('/seafarers', data);
};

export const signupUpdate = (
  firstName: string,
  lastName: string,
  nationality: string,
  email: string,
  phone: string,
  dateOfBirth: string,
  communicationMedium: string,
  password: string,
  utm?: Utm,
  claimed = 'CLAIMED',
  newRegistrationFlow = true,
) => {
  return axios.post(
    '/seafarers-update',
    snakeize({
      firstName,
      lastName,
      nationality,
      email,
      phone,
      dateOfBirth,
      communicationMedium,
      password,
      utm,
      claimed,
      platform: getBrandType('id'),
      newRegistrationFlow,
    }),
  );
};

const mapProfile = (dto: any): Seafarer => ({
  firstName: dto.first_name,
  lastName: dto.last_name,
  phone: dto.phone,
  rank: dto.rank,
  availableFrom: dto.availability_from,
  citizenship: dto.nationality,
  dateOfBirth: dto.date_of_birth,
  desiredVessel: dto.desired_vessel,
  minSalary: dto.min_salary,
  height: dto.height,
  weight: dto.weight,
  communicationMedium: dto.communication_medium,
  avatar: dto.avatar,
  streetAddress: dto.street_address,
  city: dto.city,
  region: dto.region,
  postalCode: dto.postal_code,
  country: dto.country,
  homeAirport: dto.home_airport,
  signupCompleted: dto.signup_completed,
  experienceCompleted: dto.experience_completed,
  claimed: dto.claimed,
  sssNumber: dto.social_security_system_number,
  philHealthNumber: dto.phil_health_number,
  pagIbigNumber: dto.pag_ibig_number,
  secondaryPhoneNumber: dto.secondary_phone_number,
  secondaryPhoneCell: dto.secondary_phone_cell,
  newRegistrationFlow: dto.new_registration_flow,
  hasSeafairPool: dto.has_seafair_pool,
  availabilityStatus: dto.availability_status,
  availabilityStatusOutdated: dto.availability_status_outdated,
  availabilityDateOutdated: dto.availability_date_outdated,
  utmSource: dto.utm_source,
  createdBy: dto.created_by,
  fatherName: dto.father_name,
  motherName: dto.mother_name,
});

const mapPlacement = (dto: any): Placement => ({
  id: dto.id,
  contract: dto.seafarer_contract,
  salary: dto.salary,
  imo: dto.imo,
  startDate: dto.start_date,
  duration: dto.duration,
  durationMin: dto.duration_min,
  durationMax: dto.duration_max,
});

const mapLoginResponse = (dto: any): SeafarerLoginSerializer => ({
  seafarerProfile: mapProfile(dto.seafarer_profile),
  seafairRanks: dto.seafair_ranks,
});

export const getProfileOnLogin = (token: string, utm?: Utm): Promise<SeafarerLoginSerializer> => {
  const headers = { Authorization: `Bearer ${token}` };
  return axios.put('/seafarers/login', { utm }, { headers }).then((response) => mapLoginResponse(response.data.data));
};

export const logout = (token: string): Promise<any> => {
  const headers = { Authorization: `Bearer ${token}` };
  return axios.post('/logout', {}, { headers });
};

export const getMyProfile = (token: string): Promise<Seafarer> => {
  const headers = { Authorization: `Bearer ${token}` };
  return axios.get('/seafarers/me', { headers }).then((response) => mapProfile(response.data.data));
};

export const updateMyProfile = (token: string, values: FormikValues | Seafarer) => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = {
    first_name: values.firstName,
    last_name: values.lastName,
    phone: values.phone,
    nationality: values.citizenship,
    date_of_birth: values.dateOfBirth.format('YYYY-MM-DD'),
    availability_from: values.availableFrom ? values.availableFrom.format('YYYY-MM-DD') : null,
    desired_vessel: values.desiredVessel,
    rank: values.rank,
    min_salary: values.minSalary,
    height: values.height,
    weight: values.weight,
    communication_medium: values.communicationMedium,
    social_security_system_number: values.sssNumber,
    phil_health_number: values.philHealthNumber,
    pag_ibig_number: values.pagIbigNumber,
    secondary_phone_number: values.secondaryPhoneNumber,
    secondary_phone_cell: values.secondaryPhoneCell,
    father_name: values.fatherName || null,
    mother_name: values.motherName || null,
  };
  return axios.put('/seafarers/me', data, { headers });
};

export const updateAvatar = (token: string, url: string): Promise<any> => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = {
    avatar: url,
  };
  return axios.put('/seafarers/me/avatar', data, { headers });
};

export const deleteAvatar = (token: string): Promise<any> => {
  const headers = { Authorization: `Bearer ${token}` };
  return axios.delete('/seafarers/me/avatar', { headers });
};

export const updateEmail = (token: string, email: string): Promise<void> => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = { email };
  return axios.put('/seafarers/me/email', data, { headers });
};

export const updateAddress = (token: string, formValues: FormikValues): Promise<void> => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = {
    street_address: formValues.streetAddress,
    city: formValues.city,
    region: formValues.region,
    postal_code: formValues.postalCode,
    country: formValues.country,
    home_airport: formValues.homeAirport?.icao || null,
  };
  return axios.put('/seafarers/me/address', data, { headers });
};

const mapSeafarerChild = (dto: any): SeafarerChild => ({
  id: dto.seafarer_child_id,
  name: dto.name,
  gender: dto.gender,
  birthDate: dto.birth_date,
});

const mapSeafarerFamily = (dto: any): SeafarerFamily => ({
  maritalStatus: dto.marital_status,
  spouseFirstName: dto.spouse_first_name,
  spouseLastName: dto.spouse_last_name,
  spouseEmail: dto.spouse_email,
  spousePhone: dto.spouse_phone,
  spouseAddress: dto.spouse_address,
  spouseBirthday: dto.spouse_birthday,
  children: dto.children.map((child: any): SeafarerChild => mapSeafarerChild(child)),
});

const mapNextOfKin = (dto: any): SeafarerNextOfKin => ({
  id: dto.seafarer_next_of_kin_id,
  firstName: dto.first_name,
  lastName: dto.last_name,
  relationship: dto.relationship,
  email: dto.email,
  phone: dto.phone,
  address: dto.address,
});

export const getSeafarerFamily = (token: string): Promise<SeafarerFamily> => {
  const headers = { Authorization: `Bearer ${token}` };
  return axios
    .get('/seafarers/me/family', { headers })
    .then((response) => (response.status === 200 ? mapSeafarerFamily(response.data.data) : null)); // 204 for empty family
};

export const updateSeafarerFamily = (token: string, formValues: FormikValues): Promise<SeafarerFamily> => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = {
    marital_status: formValues.maritalStatus,
    spouse_first_name: formValues.firstName || null,
    spouse_last_name: formValues.lastName || null,
    spouse_email: formValues.email || null,
    spouse_phone: formValues.phone || null,
    spouse_address: formValues.address || null,
    spouse_birthday: formValues.birthday ? formValues.birthday.format('YYYY-MM-DD') : null,
  };
  return axios.put('/seafarers/me/family', data, { headers }).then((response) => mapSeafarerFamily(response.data.data));
};

export const updateSeafarerPassword = (
  token: string,
  formValues: FormikValues,
  utm: Utm,
  isSignup: boolean,
): Promise<void> => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = {
    password: formValues.password,
    signup: isSignup,
    utm,
  };
  return axios.put('/users/me/update-password', data, { headers });
};

export const createSeafarerChild = (token: string, formValues: FormikValues): Promise<SeafarerChild> => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = {
    name: formValues.name,
    gender: formValues.gender,
    birth_date: formValues.birthDate.format('YYYY-MM-DD'),
  };
  return axios
    .post('/seafarers/me/family/children', data, { headers })
    .then((response) => mapSeafarerChild(response.data.data));
};

export const updateSeafarerChild = (
  token: string,
  childId: string,
  formValues: FormikValues,
): Promise<SeafarerChild> => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = {
    name: formValues.name,
    gender: formValues.gender,
    birth_date: formValues.birthDate.format('YYYY-MM-DD'),
  };
  return axios
    .put(`/seafarers/me/family/children/${childId}`, data, { headers })
    .then((response) => mapSeafarerChild(response.data.data));
};

export const deleteSeafarerChild = (token: string, childId: string): Promise<void> => {
  const headers = { Authorization: `Bearer ${token}` };
  return axios.delete(`/seafarers/me/family/children/${childId}`, { headers });
};

export const getSeafarerNextOfKin = (token: string): Promise<SeafarerNextOfKin> => {
  const headers = { Authorization: `Bearer ${token}` };
  return axios
    .get('/seafarers/me/next-of-kins', { headers })
    .then((response) => (response.data.data.length > 0 ? mapNextOfKin(response.data.data[0]) : null));
};

export const createNextOfKin = (token: string, formValues: any): Promise<SeafarerNextOfKin> => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = {
    first_name: formValues.firstName,
    last_name: formValues.lastName,
    relationship: formValues.relationship,
    email: formValues.email,
    phone: formValues.phone,
    address: formValues.address,
  };
  return axios
    .post('/seafarers/me/next-of-kins', data, { headers })
    .then((response) => mapNextOfKin(response.data.data));
};

export const updateNextOfKin = (token: string, nextOfKinId: string, formValues: any): Promise<SeafarerNextOfKin> => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = {
    first_name: formValues.firstName,
    last_name: formValues.lastName,
    relationship: formValues.relationship,
    email: formValues.email,
    phone: formValues.phone,
    address: formValues.address,
  };
  return axios
    .put(`/seafarers/me/next-of-kins/${nextOfKinId}`, data, { headers })
    .then((response) => mapNextOfKin(response.data.data));
};

export const getPlacements = (token: string): Promise<Placement[]> => {
  const headers = { Authorization: `Bearer ${token}` };
  return axios
    .get('/seafarers/me/placements', { headers })
    .then((response) => response.data.data.map((dto) => mapPlacement(dto)));
};

export const getVacanciesPreview = (token: string, rank?: string, vesselType?: string): Promise<any[]> => {
  const headers = { Authorization: `Bearer ${token}` };
  const params = new URLSearchParams();
  if (rank) {
    params.append('rank', rank);
  }
  if (vesselType) {
    params.append('vessel_type', vesselType);
  }

  return axios
    .get(`/vacancies-preview?${params.toString()}`, { headers })
    .then((response) => response.data.data.map((item) => camelize(item)));
};

export const getVacancies = (token: string): Promise<Vacancy[]> => {
  const headers = { Authorization: `Bearer ${token}` };

  return axios.get('/vacancies', { headers }).then((response) => response.data.data.map((item) => camelize(item)));
};

export const getPaginatedVacancies = (
  token: string,
  allVacancies?: boolean,
  limit?: number,
  offset?: number,
): Promise<Vacancy[]> => {
  const headers = { Authorization: `Bearer ${token}` };
  const params = new URLSearchParams();

  if (allVacancies) {
    params.append('all_vacancies', String(allVacancies));
  }

  if (limit) {
    params.append('limit', limit.toString());
  }

  if (offset) {
    params.append('offset', offset.toString());
  }

  return axios
    .get(`/vacancies?${params}`, { headers })
    .then((response) => response.data.data.items.map((item) => camelize(item)));
};

export const getVacancy = (token: string, vacancyId: string): Promise<Vacancy> => {
  const headers = { Authorization: `Bearer ${token}` };
  return axios.get(`/vacancies/${vacancyId}`, { headers }).then(({ data }) => camelize(data.data));
};

export const getSeafarerProfileCompletion = (token: string): Promise<SeafarerProfileCompletion> => {
  const headers = { Authorization: `Bearer ${token}` };
  return axios.get('/seafarers/me/profile-completion', { headers }).then((response) => {
    const dto = response.data.data;

    return camelize(dto);
  });
};

export const getSeafarerVesselExperience = (token: string): Promise<SeafarerVesselExperience> => {
  const headers = { Authorization: `Bearer ${token}` };
  return axios.get('/seafarers/me/vessel-experience', { headers }).then((response) => {
    const dto = response.data.data;

    return camelize(dto);
  });
};

export const updateSeafarerVesselExperience = (
  token: string,
  vesselExperience: SeafarerVesselExperience,
): Promise<void> => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = snakeize(vesselExperience);

  data.vessel_size_experience = vesselExperience.vesselSizeExperience.map((item) =>
    Object.entries(item)
      .filter(([, v]) => v !== null)
      .reduce((acc, [k, v]) => {
        acc[k] = v;
        return acc;
      }, {}),
  );
  return axios.put(`/seafarers/me/vessel-experience`, data, { headers });
};

export const updateSeafarerAvailabilityDate = (token: string, availabilityDate: string): Promise<void> => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = {
    availability_date: availabilityDate,
  };
  return axios.put(`/seafarers/me/availability-date`, data, { headers });
};

export const updateAvailabilityStatus = (token: string, availabilityStatus: AvailabilityStatus): Promise<void> => {
  const headers = { Authorization: `Bearer ${token}` };
  return axios.put(`/seafarers/me/availability-status`, snakeize({ availabilityStatus }), { headers });
};

export const updateSeafarerCallBackDate = (token: string, callBackDate: string): Promise<void> => {
  const headers = { Authorization: `Bearer ${token}` };
  return axios.put(`/seafarers/me/call-back-date`, snakeize({ callBackDate }), { headers });
};

export const updateSeafarerPhone = (token: string, phone: string): Promise<void> => {
  const headers = { Authorization: `Bearer ${token}` };
  return axios.put(`/seafarers/me/phone`, snakeize({ phone }), { headers });
};

export const updateSeafarerCommunication = (
  token: string,
  communicationMedium: string,
  communicationMediumId?: string,
): Promise<void> => {
  const headers = { Authorization: `Bearer ${token}` };
  return axios.put(`/seafarers/me/communication-medium`, snakeize({ communicationMedium, communicationMediumId }), {
    headers,
  });
};

export const applyToVacancy = (token: string, applicationId: string, seafarerRequestId?: string): Promise<void> => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = { seafarer_request_id: seafarerRequestId };
  return axios.put(`/vacancies/${applicationId}/apply`, data, { headers });
};

export const removeApplication = (token: string, seafarerVacancyApplicationId: string): Promise<void> => {
  const headers = { Authorization: `Bearer ${token}` };
  const data = {};
  return axios.put(`/vacancies/${seafarerVacancyApplicationId}/withdraw`, data, { headers });
};

export const getApplications = (
  token: string,
  applicationFilters?: ApplicationFilters,
  applicationRecentlyUpdatedFilter?: boolean,
): Promise<any[]> => {
  const headers = { Authorization: `Bearer ${token}` };
  const params = new URLSearchParams();

  if (applicationFilters) {
    if (applicationFilters.statuses && applicationFilters.statuses.length > 0) {
      applicationFilters.statuses.forEach((applicationStatus) => {
        params.append('status', applicationStatus);
      });
    }
  }
  if (applicationRecentlyUpdatedFilter) {
    params.append('recently_updated', String(applicationRecentlyUpdatedFilter));
  }

  return axios.get(`/applications?${params.toString()}`, { headers }).then((response) => {
    return response.data.data.map((item) => camelize(item));
  });
};

export const sendResetPasswordEmail = (email: string): Promise<void> => {
  return axios.post('/send-password-reset-email', { email });
};

export const resendSignInLink = (email: string, type: string, id?: string): Promise<void> => {
  const data = {
    email,
    type,
    id: id || null,
  };
  return axios.post('/resend-sign-in-link', data);
};

export const deleteSeafarer = (token: string): Promise<void> => {
  const headers = { Authorization: `Bearer ${token}` };
  return axios.delete('/seafarers/me', { headers });
};
