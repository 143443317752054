import React from 'react';
import type { FC } from 'react';
import { getBrandType } from 'src/constants/brandTypes';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => {
  const logoWhite = getBrandType('logoWhite');
  return getBrandType('id') === 'LT_MARINE' ? (
    <img alt="Logo" height="75" src={logoWhite} {...props} />
  ) : (
    <img alt="Logo" height="40" src={logoWhite} {...props} />
  );
};

export default Logo;
