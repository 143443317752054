import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { useQueryParam, StringParam } from 'use-query-params';

const handleRedirectContinueUrl = (loginContinueUrl, pathname, children) => {
  const continueUrlVisited = sessionStorage.getItem('continueUrlVisited');

  const continuePathWithParams = loginContinueUrl.replace(process.env.REACT_APP_SELF_HOME_URL, '');
  const continuePath = continuePathWithParams.substring(0, continuePathWithParams.indexOf('?'));
  const params = continuePathWithParams.substring(continuePathWithParams.indexOf('?'));

  if (pathname.includes(continuePath)) {
    sessionStorage.setItem('continueUrlVisited', 'true');
    return <>{children}</>;
  }
  if (continueUrlVisited === 'false') {
    return <Redirect to={{ pathname: continuePath, search: params }} />;
  }

  return null;
};

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { pathname } = useLocation();
  const { isAuthenticated, user, seafarer } = useAuth();
  const [stepParam] = useQueryParam('step', StringParam);

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { referrer: pathname },
        }}
      />
    );
  }

  const onboardingPath = '/app/onboarding-2';
  const dashboardPath = seafarer.newRegistrationFlow ? '/app/dashboard-2' : '/app/dashboard';
  const profileStep = '?step=profile';
  const experienceStep = `?step=${seafarer.newRegistrationFlow ? 'experience1' : 'resume'}`;
  const passwordStep = '?step=password';

  if (isAuthenticated) {
    const loginContinueUrl = sessionStorage.getItem('continueUrl');

    if (loginContinueUrl) {
      const routeToRedirect = handleRedirectContinueUrl(loginContinueUrl, pathname, children);
      if (routeToRedirect) {
        return routeToRedirect;
      }
    }

    if (!seafarer.hasSeafairPool) {
      if (pathname.startsWith('/app/my-shipcos')) {
        return <>{children}</>;
      }
      return <Redirect to={{ pathname: '/app/my-shipcos' }} />;
    }

    if (
      process.env.REACT_APP_FEATURE_CLAIM_ACCOUNT_V2 === 'true' &&
      user.claimed === 'UNCLAIMED' &&
      seafarer.createdBy !== user.id &&
      seafarer.hasSeafairPool
    ) {
      if (pathname !== '/claim-account-v2-profile') {
        return <Redirect to={{ pathname: '/claim-account-v2-profile', state: { handleNavigation: true } }} />;
      }
      return <>{children}</>;
    }

    if (user.claimed === 'UNCLAIMED' && seafarer.createdBy === user.id && seafarer.hasSeafairPool) {
      if (pathname !== '/app/onboarding-2') {
        return <Redirect to={{ pathname: onboardingPath, search: profileStep }} />;
      }
    }

    // TODO remove this once the claim account v2 is released
    if (!user.signupCompleted && (pathname !== onboardingPath || !stepParam)) {
      return <Redirect to={{ pathname: onboardingPath, search: profileStep }} />;
    }
    // TODO remove this once the claim account v2 is released
    if (user.signupCompleted && !user.experienceCompleted && pathname !== onboardingPath) {
      return <Redirect to={{ pathname: onboardingPath, search: experienceStep }} />;
    }
    // TODO remove this once the claim account v2 is released
    if (
      user.signupCompleted &&
      user.experienceCompleted &&
      user.claimed === 'UNCLAIMED' &&
      pathname !== onboardingPath &&
      process.env.REACT_APP_FEATURE_CLAIM_ACCOUNT_V2 !== 'true'
    ) {
      return <Redirect to={{ pathname: onboardingPath, search: passwordStep }} />;
    }
    // TODO handle and test this when claim account v2 is done
    if (user.signupCompleted && user.experienceCompleted && user.claimed === 'CLAIMED' && pathname === onboardingPath) {
      return <Redirect to={{ pathname: dashboardPath, search: '?flag=onboarding' }} />;
    }
  }

  return <>{children}</>;
};

export default AuthGuard;
