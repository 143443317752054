import { hotjar } from 'react-hotjar';

const HOTJAR_ENABLED = process.env.REACT_APP_HOTJAR_ENABLED === 'true';
const HOTJAR_SITE_ID = 2760148;
const HOTJAR_TRACKING_CODE_VERSION = 6;

export const initializeHotjar = () => {
  if (HOTJAR_ENABLED) {
    hotjar.initialize(HOTJAR_SITE_ID, HOTJAR_TRACKING_CODE_VERSION);
  }
};

export default initializeHotjar;
