import React, { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import type { User } from 'src/models/user';
import { AvailabilityStatus, Seafarer } from 'src/models/seafarer';
import type { Utm } from 'src/models/utm';
import SplashScreen from 'src/components/SplashScreen';
import firebase from 'src/lib/firebase';
import { checkEmail, getProfileOnLogin, logout, signup, signupUpdate } from 'src/services/user';
import { useSnackbar } from 'notistack';
import { getFromStorage, saveToStorage } from 'src/lib/localStorage';
import { SHOW_ONBOARDING_POPUP } from 'src/constants/storageConstants';
import { getUtmFromStorage, removeUtmFromStorage } from 'src/utils/signup';
import { setSentryTag } from 'src/lib/sentry';
import { SeafarerRank } from 'src/models/rank';

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  loginLoading: boolean;
  user: User | null;
  seafarer: Seafarer | null;
  loginContinueUrl?: string;
  seafairRanks: SeafarerRank[] | null;
}

interface UserUpdateRequest {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface AuthContextValue extends AuthState {
  method: 'FirebaseAuth';
  createUserWithEmailAndPassword: (
    email: string,
    password: string,
    phone: string,
    utm?: Utm,
    claimed?: string,
    newRegistrationFlow?: boolean,
  ) => Promise<any>;
  createUpdateUserWithEmailAndPassword: (
    firstName: string,
    lastName: string,
    nationality: string,
    email: string,
    phone: string,
    dateOfBirth: string,
    communicationMedium: string,
    password: string,
    utm?: Utm,
    claimed?: string,
    newRegistrationFlow?: boolean,
  ) => Promise<any>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signInWithEmailLink: (email: string, emailLink: string, continueUrl?: string) => Promise<any>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
  setSignUpCompleted: () => void;
  setExperienceCompleted: () => void;
  setAccountClaimed: () => void;
  updateProfile: (user: UserUpdateRequest, seafarer: Seafarer) => void;
  updateAvatar: (url?: string) => void;
  updateEmail: (email: string) => void;
  updateAvailabilityStatus: (availabilityStatus: AvailabilityStatus) => void;
  confirmPasswordReset(actionCode: string, newPassword: string): Promise<void>;
  logout: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type AuthStateChangedAction = {
  type: 'AUTH_STATE_CHANGED';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    seafarer: Seafarer | null;
    loginContinueUrl?: string;
    seafairRanks: SeafarerRank[] | null;
  };
};

type SignupCompletedAction = {
  type: 'SIGNUP_COMPLETED';
};

type ExperienceCompletedAction = {
  type: 'EXPERIENCE_COMPLETED';
};

type AccountClaimedAction = {
  type: 'ACCOUNT_CLAIMED';
};

type ProfileUpdatedAction = {
  type: 'PROFILE_UPDATED';
  payload: {
    seafarer: Seafarer | null;
    user: UserUpdateRequest | null;
  };
};

type AvatarUpdatedAction = {
  type: 'AVATAR_UPDATED';
  payload: {
    avatarUrl: string | null;
  };
};

type EmailUpdatedAction = {
  type: 'EMAIL_UPDATED';
  payload: {
    email: string;
  };
};

type AvailabilityStatusUpdatedAction = {
  type: 'AVAILABILITY_STATUS_UPDATED';
  payload: {
    availabilityStatus: AvailabilityStatus;
  };
};

type LoginStartAction = {
  type: 'LOGIN_START';
};

type LoginEndAction = {
  type: 'LOGIN_END';
};

type Action =
  | AuthStateChangedAction
  | SignupCompletedAction
  | ExperienceCompletedAction
  | AccountClaimedAction
  | ProfileUpdatedAction
  | AvatarUpdatedAction
  | EmailUpdatedAction
  | AvailabilityStatusUpdatedAction
  | LoginStartAction
  | LoginEndAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  loginLoading: false,
  user: null,
  seafarer: null,
  seafairRanks: null,
};

export const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user, seafarer, seafairRanks } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
        seafarer,
        seafairRanks,
      };
    }
    case 'SIGNUP_COMPLETED': {
      const newUser = {
        ...state.user,
        signupCompleted: true,
      };

      return {
        ...state,
        user: newUser,
      };
    }
    case 'EXPERIENCE_COMPLETED': {
      const newUser = {
        ...state.user,
        experienceCompleted: true,
      };

      return {
        ...state,
        user: newUser,
      };
    }
    case 'ACCOUNT_CLAIMED': {
      const newUser = {
        ...state.user,
        claimed: 'CLAIMED',
      };

      return {
        ...state,
        user: newUser,
      };
    }
    case 'PROFILE_UPDATED': {
      const { user, seafarer } = action.payload;

      const newUser = {
        ...state.user,
        ...user,
      };

      const newSeafarer = {
        ...state.seafarer,
        ...seafarer,
      };

      return {
        ...state,
        user: newUser,
        seafarer: newSeafarer,
      };
    }
    case 'AVATAR_UPDATED': {
      const { avatarUrl } = action.payload;

      const newUser = {
        ...state.user,
        avatar: avatarUrl,
      };

      return {
        ...state,
        user: newUser,
      };
    }
    case 'EMAIL_UPDATED': {
      const { email } = action.payload;

      const newUser = {
        ...state.user,
        email,
      };

      return {
        ...state,
        user: newUser,
      };
    }
    case 'AVAILABILITY_STATUS_UPDATED': {
      const { availabilityStatus } = action.payload;

      return {
        ...state,
        seafarer: {
          ...state.seafarer,
          availabilityStatus,
          availabilityStatusOutdated: false,
        },
      };
    }
    case 'LOGIN_START': {
      return {
        ...state,
        loginLoading: true,
      };
    }
    case 'LOGIN_END': {
      return {
        ...state,
        loginLoading: false,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  createUpdateUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailLink: () => Promise.resolve(),
  sendPasswordResetEmail: () => Promise.resolve(),
  setSignUpCompleted: () => Promise.resolve(),
  setExperienceCompleted: () => Promise.resolve(),
  setAccountClaimed: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  updateAvatar: () => Promise.resolve(),
  updateEmail: () => Promise.resolve(),
  updateAvailabilityStatus: () => Promise.resolve(),
  confirmPasswordReset: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { enqueueSnackbar } = useSnackbar();

  const createUserWithEmailAndPassword = async (
    email: string,
    password: string,
    phone: string,
    utm?: Utm,
    claimed = 'CLAIMED',
    newRegistrationFlow = false,
  ): Promise<any> => {
    const response = await signup(email, password, phone, utm, claimed, newRegistrationFlow);
    const { token } = response.data.data;
    return firebase.auth().signInWithCustomToken(token);
  };

  const createUpdateUserWithEmailAndPassword = async (
    firstName: string,
    lastName: string,
    nationality: string,
    email: string,
    phone: string,
    dateOfBirth: string,
    communicationMedium: string,
    password: string,
    utm?: Utm,
    claimed = 'CLAIMED',
    newRegistrationFlow = true,
  ): Promise<any> => {
    const response = await signupUpdate(
      firstName,
      lastName,
      nationality,
      email,
      phone,
      dateOfBirth,
      communicationMedium,
      password,
      utm,
      claimed,
      newRegistrationFlow,
    );
    const { token } = response.data.data;
    return firebase.auth().signInWithCustomToken(token);
  };

  const signInWithEmailAndPassword = async (email: string, password: string): Promise<any> => {
    dispatch({ type: 'LOGIN_START' });
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch((err) => {
        dispatch({ type: 'LOGIN_END' });
        throw err;
      });
  };

  const signInWithEmailLink = async (email: string, emailLink: string, continueUrl?: string): Promise<any> => {
    dispatch({ type: 'LOGIN_START' });
    return firebase
      .auth()
      .signInWithEmailLink(email, emailLink)
      .then((UserCredential) => {
        sessionStorage.setItem('continueUrl', continueUrl);
        sessionStorage.setItem('continueUrlVisited', 'false');
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: { isAuthenticated: false, seafarer: null, user: null, seafairRanks: null },
        });
        return UserCredential;
      })
      .catch((err) => {
        dispatch({ type: 'LOGIN_END' });
        throw err;
      });
  };

  const sendPasswordResetEmail = (email: string): Promise<void> => {
    return checkEmail(email).then(() => {
      return firebase.auth().sendPasswordResetEmail(email, { url: process.env.REACT_APP_SELF_HOME_URL });
    });
  };

  const handleLogout = async (): Promise<void> => {
    if (!state.user) {
      await firebase.auth().signOut();
      return;
    }
    await logout(state.user.token);
    await firebase.auth().signOut();
  };

  const setSignUpCompleted = () => {
    dispatch({ type: 'SIGNUP_COMPLETED' });
  };

  const setExperienceCompleted = () => {
    dispatch({ type: 'EXPERIENCE_COMPLETED' });
  };

  const setAccountClaimed = () => {
    dispatch({ type: 'ACCOUNT_CLAIMED' });
  };

  const updateProfile = (user: UserUpdateRequest, seafarer: Seafarer): void => {
    dispatch({ type: 'PROFILE_UPDATED', payload: { user, seafarer } });
  };

  const updateAvatar = (avatarUrl?: string) => {
    dispatch({ type: 'AVATAR_UPDATED', payload: { avatarUrl } });
  };

  const updateEmail = (email: string) => {
    dispatch({ type: 'EMAIL_UPDATED', payload: { email } });
  };

  const updateAvailabilityStatus = (availabilityStatus: AvailabilityStatus) => {
    dispatch({ type: 'AVAILABILITY_STATUS_UPDATED', payload: { availabilityStatus } });
  };

  const confirmPasswordReset = (code: string, newPassword: string): Promise<void> => {
    return firebase.auth().confirmPasswordReset(code, newPassword);
  };

  const handleOnboardingPopup = () => {
    const showOnboardingPopup = getFromStorage(SHOW_ONBOARDING_POPUP);
    if (showOnboardingPopup === null) {
      saveToStorage(SHOW_ONBOARDING_POPUP, true);
    }
  };

  const signInCalypso = (user: firebase.User, token: string, trialsRemaining = 3) => {
    const utm = getUtmFromStorage('login-page');
    getProfileOnLogin(token, utm)
      .then((response) => {
        const { seafarerProfile, seafairRanks } = response;
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: true,
            user: {
              id: user.uid,
              token,
              avatar: seafarerProfile.avatar,
              email: user.email,
              firstName: seafarerProfile.firstName || '',
              lastName: seafarerProfile.lastName || '',
              emailVerified: user.emailVerified,
              signupCompleted: seafarerProfile.signupCompleted,
              experienceCompleted: seafarerProfile.experienceCompleted,
              phone: seafarerProfile.phone,
              claimed: seafarerProfile.claimed,
              utmSource: seafarerProfile.utmSource ?? null,
            },
            seafarer: seafarerProfile,
            seafairRanks,
          },
        });
        dispatch({ type: 'LOGIN_END' });
        removeUtmFromStorage();
        handleOnboardingPopup();
        setSentryTag('userId', user.uid);
      })
      .catch(() => {
        if (trialsRemaining > 0) {
          signInCalypso(user, token, trialsRemaining - 1);
        } else {
          dispatch({ type: 'LOGIN_END' });
        }
      });
  };

  useEffect(() => {
    return firebase.auth().onIdTokenChanged(async (user) => {
      if (user) {
        // extract the complete user profile to make it available in your entire app
        const { token, claims } = await user.getIdTokenResult();
        if (claims.roles?.includes('SEAFARER')) {
          signInCalypso(user, token);
        } else {
          enqueueSnackbar('You are not allowed to access this website', {
            variant: 'error',
          });
          // eslint-disable-next-line
          await handleLogout();
          dispatch({ type: 'LOGIN_END' });
        }
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null,
            seafarer: null,
            seafairRanks: null,
          },
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!state.isInitialized) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        createUpdateUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithEmailLink,
        sendPasswordResetEmail,
        setSignUpCompleted,
        setExperienceCompleted,
        setAccountClaimed,
        updateProfile,
        updateAvatar,
        updateEmail,
        updateAvailabilityStatus,
        confirmPasswordReset,
        logout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
