import type { FC, ReactNode } from 'react';
import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { Badge, Box, Button, Collapse, ListItem, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import type { Theme } from 'src/theme';
import { Label } from '@material-ui/icons';

interface NavItemProps {
  children?: ReactNode;
  className?: string;
  depth: number;
  href?: string;
  icon?: any;
  customIcon?: any;
  info?: any;
  open?: boolean;
  title: string;
  badge?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    color: '#6F6F6F',
  },
  title: {
    color: theme.palette.secondary.main,
    marginRight: 'auto',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  active: {
    color: theme.palette.primary.dark,
    '& $title': {
      color: theme.palette.primary.dark,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $icon': {
      color: theme.palette.primary.dark,
    },
  },
}));

const NavItem: FC<NavItemProps> = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  customIcon,
  info: Info,
  open: openProp,
  title,
  badge,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(openProp);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;
  if (depth > 0) {
    paddingLeft = 20;
  }

  const style = { paddingLeft };
  if (children) {
    return (
      <ListItem className={clsx(classes.item, className)} disableGutters key={title} {...rest}>
        <Button className={classes.button} onClick={handleToggle} style={style}>
          <Box display="flex">
            <Box width="35px">
              {Icon && <Icon className={classes.icon} size="20" />}
              {customIcon && (
                <img
                  src={customIcon}
                  alt={title}
                  width="20"
                  height="20"
                  className={classes.icon}
                  style={{ marginLeft: '3px' }}
                />
              )}
            </Box>
            <Box>
              <span className={classes.title}>{title}</span>
              <Label>NEW</Label>
            </Box>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem className={clsx(classes.itemLeaf, className)} disableGutters key={title} {...rest}>
      <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        exact
        style={style}
        to={href}
      >
        <Box display="flex">
          <Box width="35px">
            {Icon && <Icon className={classes.icon} size="20" />}
            {customIcon && (
              <img
                src={customIcon}
                alt={title}
                width="20"
                height="20"
                className={classes.icon}
                style={{ marginLeft: '3px' }}
              />
            )}
          </Box>
          <Box>
            {badge === undefined ? (
              <span className={classes.title}>{title}</span>
            ) : (
              <Badge badgeContent={badge} overlap="rectangular" color="primary">
                <span className={classes.title}>{title}</span>
              </Badge>
            )}
          </Box>
          {Info && <Info />}
        </Box>
      </Button>
    </ListItem>
  );
};

export default NavItem;
