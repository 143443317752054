import React from 'react';
import type { FC } from 'react';
import { getBrandType } from 'src/constants/brandTypes';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => {
  const logo = getBrandType('logo');
  return getBrandType('id') === 'LT_MARINE' ? (
    <img alt="Logo" height="100" src={logo} {...props} />
  ) : (
    <img alt="Logo" height="40" src={logo} {...props} />
  );
};

export default Logo;
