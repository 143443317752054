/* eslint no-extend-native: 0 */
import moment from 'moment-timezone';

declare module 'moment' {
  export interface Moment {
    formatDate(): string;
    inTimezone(): string;
  }
}

(moment.fn as any).formatDate = function formatDate(): string {
  return this.format('DD MMM YYYY');
};

(moment.fn as any).inTimezone = function inTimezone(): string {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return this.tz(timezone).format('DD MMM YYYY HH:mm');
};
