import { lazy } from 'react';

import { getFromStorage, saveToStorage } from 'src/lib/localStorage';

const FAILED_IMPORT_FORCED_REFRESH = 'FAILED_IMPORT_FORCED_REFRESH';

// Adapted from https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
const lazyWithRetry = (componentImport: () => Promise<any>) =>
  lazy(async () => {
    const alreadyForcedRefresh = getFromStorage(FAILED_IMPORT_FORCED_REFRESH) || false;

    try {
      // importPath is encapsulated in a string template as it is a hard requirement for this to be a string
      const component = await componentImport();

      saveToStorage(FAILED_IMPORT_FORCED_REFRESH, false);

      return component;
    } catch (error) {
      if (!alreadyForcedRefresh) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        saveToStorage(FAILED_IMPORT_FORCED_REFRESH, true);
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

export default lazyWithRetry;
