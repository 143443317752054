import React, { Fragment, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AuthGuard from 'src/components/AuthGuard';
import ExternalGuard from 'src/components/ExternalGuard';
import GuestGuard from 'src/components/GuestGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardLayout from 'src/layouts/DashboardLayout';
import OnboardingLayout from 'src/layouts/OnboardingLayout';
import lazy from 'src/utils/componentLoading';

type RouteObject = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  enabled?: boolean;
  alternateComponent?: any; // the alternate component if the main component is not enabled
  routes?: Routes;
  id?: string;
};

type Routes = RouteObject[];

export const renderRoutes = (routes: Routes = [], seafarer?: any): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const componentEnabled = route.enabled === undefined ? true : route.enabled;
        const Component = componentEnabled ? route.component : route.alternateComponent;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes, seafarer) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const maintenanceRoute: RouteObject = {
  exact: true,
  path: '*',
  component: lazy(() => import('src/views/errors/MaintenanceView')),
};

const routes: Routes = [
  {
    exact: true,
    path: '/',
    // eslint-disable-next-line
    component: () => <Redirect to="/login" />,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/signup',
    component: lazy(() => import('src/views/auth/RegisterViewV2')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/signup-2',
    component: lazy(() => import('src/views/auth/RegisterViewV2')),
  },
  {
    exact: true,
    path: '/register-account',
    component: lazy(() => import('src/views/auth/RegisterAccountView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset-password',
    component: lazy(() => import('src/views/auth/ResetPasswordView')),
  },
  {
    exact: true,
    guard: ExternalGuard,
    path: '/claim-account',
    component: lazy(() => import('src/views/auth/ClaimAccountView')),
  },
  {
    exact: true,
    path: '/claim-account-v2',
    guard: GuestGuard,
    enabled: process.env.REACT_APP_FEATURE_CLAIM_ACCOUNT_V2 === 'true',
    component: lazy(() => import('src/views/auth/ClaimAccountV2/ClaimAccountV2LandingPage')),
    alternateComponent: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    path: '/claim-account-v2-profile',
    guard: AuthGuard,
    enabled: process.env.REACT_APP_FEATURE_CLAIM_ACCOUNT_V2 === 'true',
    component: lazy(() => import('src/views/auth/ClaimAccountV2/ClaimAccountV2Profile')),
    alternateComponent: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: '/take-assessment',
    component: lazy(() => import('src/views/assessments/AssessmentView')),
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: '/assessment-results',
    component: lazy(() => import('src/views/assessments/AssessmentResultView')),
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/403',
    component: lazy(() => import('src/views/errors/ForbiddenView')),
  },
  {
    exact: true,
    path: '/app/onboarding',
    guard: AuthGuard,
    layout: OnboardingLayout,
    component: lazy(() => import('src/views/onboarding/OnboardingViewV2')),
  },
  {
    exact: true,
    path: '/app/onboarding-2',
    guard: AuthGuard,
    layout: OnboardingLayout,
    component: lazy(() => import('src/views/onboarding/OnboardingViewV2')),
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: '/career-planning',
    component: lazy(() => import('src/views/seafarer-plans/SeafarerPlanView')),
  },
  {
    path: '/app',
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/dashboard',
        guard: AuthGuard,
        component: lazy(() => import('src/views/dashboard/DashboardView')),
      },
      {
        exact: true,
        path: '/app/dashboard-2',
        guard: AuthGuard,
        component: lazy(() => import('src/views/dashboard/DashboardView')),
      },
      {
        exact: true,
        path: '/app/profile',
        guard: AuthGuard,
        component: lazy(() => import('src/views/profile/ProfileView')),
      },
      {
        exact: true,
        path: '/app/profile-info',
        guard: AuthGuard,
        component: lazy(() => import('src/views/profile/ProfileInfoView')),
      },
      {
        exact: true,
        path: '/app/sea-services',
        guard: AuthGuard,
        component: lazy(() => import('src/views/sea-services/SeaServiceListView')),
      },
      {
        exact: true,
        path: '/app/documents',
        guard: AuthGuard,
        component: lazy(() => import('src/views/documents/DocumentListView')),
      },
      {
        exact: true,
        path: '/app/applications-contracts',
        guard: AuthGuard,
        component: lazy(() => import('src/views/contracts/ApplicationContractListView')),
      },
      {
        exact: true,
        path: '/app/payments',
        guard: AuthGuard,
        component: lazy(() => import('src/views/payments/PaymentView')),
      },
      {
        exact: true,
        path: '/app/employer-statistics',
        guard: AuthGuard,
        component: lazy(() => import('src/views/shipcos/ShipcoListView')),
      },
      {
        exact: true,
        path: '/app/my-shipcos',
        guard: AuthGuard,
        component: lazy(() => import('src/views/shipcos/SeafarerShipcoView')),
      },
      {
        exact: true,
        path: '/app/my-shipcos/:id',
        guard: AuthGuard,
        component: lazy(() => import('src/views/shipcos/ShipcoPortalView')),
      },
      {
        exact: true,
        path: '/app/vacancies',
        guard: AuthGuard,
        id: 'VACANCY',
        component: lazy(() => import('src/views/vacancies/VacancyListViewV2')),
        alternateComponent: lazy(() => import('src/views/errors/NotFoundView')),
      },
      {
        exact: true,
        path: '/app/vacancies/:id',
        guard: AuthGuard,
        component: lazy(() => import('src/views/vacancies/VacancyView')),
      },
      {
        exact: true,
        path: '/app/playground',
        guard: AuthGuard,
        enabled: process.env.REACT_APP_APPLICATION_TIMELINE_TESTING === 'true',
        component: lazy(() => import('src/views/playground/PlaygroundView')),
        alternateComponent: lazy(() => import('src/views/errors/NotFoundView')),
      },
      {
        // eslint-disable-next-line
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: '*',
    guard: AuthGuard, // any other route is authenticated
    routes: [
      {
        // eslint-disable-next-line
        component: () => <Redirect to="/login" />,
      },
    ],
  },
];
if (process.env.REACT_APP_TOGGLE_MAINTENANCE === 'true') {
  routes.unshift(maintenanceRoute);
}

export default routes;
