import axios from 'axios';
import firebase from 'src/lib/firebase';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_CALYPSO_URL}/api`,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response?.status === 403 &&
      error.response?.data.errors.length > 0 &&
      error.response?.data.errors[0].code === 'AUTHENTICATION_TOKEN_EXPIRED'
    ) {
      return firebase
        .auth()
        .currentUser.getIdToken(true) // Force token refresh
        .then((token) => {
          const newConfig = error.config;
          newConfig.headers.Authorization = `Bearer ${token}`;
          return instance.request(newConfig);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }

    if (error.response?.data?.errors?.length > 0) {
      return Promise.reject(error.response.data.errors[0]);
    }
    return Promise.reject(error);
  },
);

export default instance;
