import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { Box, Divider, Drawer, Hidden, IconButton, List, makeStyles, Typography } from '@material-ui/core';
import {
  DescriptionOutlined,
  Home,
  OpenInNew,
  Person,
  Apartment,
  TrendingUp,
  Work,
  ViewCompact,
} from '@material-ui/icons';
import Logo from 'src/components/Logo';
import NavItem from 'src/components/NavBar/NavItem';
import { Theme } from 'src/theme';
import { getBrandType } from 'src/constants/brandTypes';
import useAuth from 'src/hooks/useAuth';
import { Seafarer } from 'src/models/seafarer';
import ShipcoIcon from 'src/assets/icons/ShipcoIcon';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  title: string;
  icon?: ReactNode;
  customIcon?: string;
  href?: string;
  items?: Item[];
  badge?: string;
  id?: string;
  hide?: (seafarer: Seafarer) => boolean;
}

export const items: Item[] = [
  {
    title: 'Home',
    icon: Home,
    href: '/app/dashboard',
    hide: (seafarer) => !seafarer.hasSeafairPool,
  },
  {
    title: 'My Profile',
    icon: Person,
    href: '/app/profile',
    hide: (seafarer) => !seafarer.hasSeafairPool,
  },
  {
    title: 'Vacancies',
    icon: Work,
    href: '/app/vacancies',
    hide: (seafarer) => !seafarer.hasSeafairPool,
    id: 'VACANCY',
  },
  {
    title: 'Applications & Contracts',
    icon: DescriptionOutlined,
    href: '/app/applications-contracts',
    hide: (seafarer) => !seafarer.hasSeafairPool,
  },
  {
    title: 'My Shipping Companies',
    icon: ShipcoIcon,
    href: '/app/my-shipcos',
  },
  {
    title: 'Employer Statistics',
    icon: Apartment,
    href: '/app/employer-statistics',
    hide: (seafarer) => !seafarer.hasSeafairPool,
  },
  {
    title: 'Career Planning',
    icon: TrendingUp,
    href: '/career-planning',
    hide: (seafarer) => !seafarer.hasSeafairPool,
  },
  {
    title: 'Playground',
    icon: ViewCompact,
    href: '/app/playground',
    hide: (seafarer) => process.env.REACT_APP_APPLICATION_TIMELINE_TESTING !== 'true' || !seafarer.hasSeafairPool,
    badge: 'BETA',
  },
];

function renderNavItems({
  // eslint-disable-next-line
  items,
  pathname,
  seafarer,
  depth = 0,
}: {
  items: Item[];
  pathname: string;
  seafarer?: any;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {/* eslint-disable-next-line */}
      {items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, seafarer, depth }), [])}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  seafarer,
  item,
  depth = 0,
}: {
  acc: any[];
  pathname: string;
  seafarer?: any;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = item.items.some((i) => i.href === pathname);
    if (!item.hide || !item.hide(seafarer)) {
      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          customIcon={item.customIcon}
          key={key}
          open={Boolean(open)}
          title={item.title}
          badge={item.badge}
        >
          {renderNavItems({
            items: item.items,
            pathname,
            seafarer,
            depth: depth + 1,
          })}
        </NavItem>,
      );
    }
  } else if (!item.hide || !item.hide(seafarer)) {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        customIcon={item.customIcon}
        key={key}
        title={item.title}
        badge={item.badge}
      />,
    );
  }

  return acc;
}

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    width: 260,
    backgroundColor: '#FFFFFF',
  },
  desktopDrawer: {
    width: 260,
    top: 64,
    height: 'calc(100% - 64px)',
    backgroundColor: '#FFFFFF',
  },
  support: {
    backgroundColor: theme.palette.background.paper,
  },
  supportTitle: {
    color: theme.palette.primary.dark,
  },
  supportContact: {
    color: theme.palette.primary.main,
  },
  footerLink: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.text.primary,
  },
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  const { seafarer } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Hidden lgUp>
        <Box p={2} display="flex" justifyContent="center">
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Box>
      </Hidden>
      <Box p={2}>{renderNavItems({ items, pathname: location.pathname, seafarer })}</Box>
      <Box flexGrow={1} />
      <Box p={2}>
        <Box
          p={2}
          borderRadius="borderRadius"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.support}
        >
          <Box>
            <Typography variant="body2" className={classes.supportTitle}>
              Need support?
            </Typography>
            <Typography variant="body2" className={classes.supportContact}>
              Contact us
            </Typography>
          </Box>
          <Box>
            <IconButton href={`mailto:${getBrandType('email')}`}>
              <OpenInNew />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box display="flex" justifyContent="space-evenly" p={2}>
        <Box textAlign="center">
          <Typography variant="caption">&#169;{`2022 ${getBrandType('company')}`}</Typography>
          <br />
          <a className={classes.footerLink} href={getBrandType('privacyPolicy')}>
            <Typography variant="caption">Privacy Policy</Typography>
          </a>
        </Box>
        <Box textAlign="center">
          <Typography variant="caption">All rights reserved</Typography>
          <br />
          <a className={classes.footerLink} href={getBrandType('termsOfUse')}>
            <Typography variant="caption">Site Terms of Use</Typography>
          </a>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
