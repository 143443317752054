import React from 'react';
import type { FC } from 'react';
import { Route, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import { StylesProvider, ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import { AuthProvider } from 'src/contexts/FirebaseAuthContext';
import { createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { QueryParamProvider } from 'use-query-params';
import * as Sentry from '@sentry/react';
import ErrorFallback from 'src/components/ErrorFallback';
import useAuth from 'src/hooks/useAuth';
import { Helmet } from 'react-helmet';
import { getBrandType } from 'src/constants/brandTypes';
import { ApplicationsProvider } from 'src/contexts/ApplicationsContext';

const history = createBrowserHistory();

const AppWithAuthProvider: FC = () => {
  const { seafarer } = useAuth();
  return (
    <>
      <GlobalStyles />
      <ScrollReset />
      <Sentry.ErrorBoundary fallback={ErrorFallback}>{renderRoutes(routes, seafarer)}</Sentry.ErrorBoundary>
    </>
  );
};

const App: FC = () => {
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider dense maxSnack={3}>
            <Router history={history}>
              <QueryParamProvider ReactRouterRoute={Route}>
                <AuthProvider>
                  <ApplicationsProvider>
                    <AppWithAuthProvider />
                  </ApplicationsProvider>
                </AuthProvider>
              </QueryParamProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{getBrandType('label')}</title>
        <link rel="icon" id="favicon" href={getBrandType('icoPath')} />
      </Helmet>
    </ThemeProvider>
  );
};

export default App;
