const STORAGE_ROOT_KEY = 'seafarer.seafair.io';
const ONE_YEAR_IN_MILLISECONDS = 31536000000;

interface StorageItem {
  value: any;
  expiry: number;
}

export const getFromStorage = (key: string, rootKey = STORAGE_ROOT_KEY): any => {
  const rootStr = localStorage.getItem(rootKey);
  // if the root item doesn't exist, put an empty root item in the storage and return null
  if (!rootStr) {
    localStorage.setItem(rootKey, '{}');
    return null;
  }

  const root = JSON.parse(rootStr);
  // if the specific item with the given key doesn't exist, return null
  if (!root[key]) {
    return null;
  }

  const item: StorageItem = root[key];
  const now = new Date();
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from the root object, update the storage and return null
    delete root[key];
    localStorage.setItem(rootKey, JSON.stringify(root));
    return null;
  }
  return item.value;
};

// default expiration: 1 year
export const saveToStorage = (
  key: string,
  value: any,
  ttlInMillis = ONE_YEAR_IN_MILLISECONDS,
  rootKey = STORAGE_ROOT_KEY,
): void => {
  const rootStr = localStorage.getItem(STORAGE_ROOT_KEY);

  // if the root item doesn't exist, put an empty root item in the storage, else parse the root item to JSON
  let root;
  if (!rootStr) {
    root = {};
    localStorage.setItem(rootKey, JSON.stringify(root));
  } else {
    root = JSON.parse(rootStr);
  }

  // create an item which contains the original value and the time to expire and update the root item in storage
  const now = new Date();
  root[key] = {
    value,
    expiry: now.getTime() + ttlInMillis,
  };
  localStorage.setItem(rootKey, JSON.stringify(root));
};

export const removeFromStorage = (key: string, rootKey = STORAGE_ROOT_KEY): void => {
  const rootStr = localStorage.getItem(rootKey);
  // if the root item doesn't exist, put an empty root item in the storage and return null
  if (!rootStr) {
    localStorage.setItem(rootKey, '{}');
    return;
  }

  const root = JSON.parse(rootStr);
  // if the specific item with the given key doesn't exist, return null
  if (!root[key]) {
    return;
  }

  // delete the item from the root object and update the storage
  delete root[key];
  localStorage.setItem(rootKey, JSON.stringify(root));
};
