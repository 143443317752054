import React from 'react';

import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core';
import ShipIcon from 'src/assets/img/ship.svg';

const useStyles = makeStyles({
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
  },
  iconRoot: {
    textAlign: 'center',
    display: 'flex',
  },
});

export const ShipcoIcon = () => {
  const classes = useStyles();

  return (
    <Icon classes={{ root: classes.iconRoot }}>
      <img src={ShipIcon} alt="Ship" width="18" height="18" />
    </Icon>
  );
};

export default ShipcoIcon;
