import type { Utm } from 'src/models/utm';
import { RANKS } from '@seafair/frontend.constants';
import { getFromStorage, saveToStorage, removeFromStorage } from 'src/lib/localStorage';
import { VESSEL_TYPES } from 'src/constants/vesselTypes';
import moment from 'moment';

const LANGUAGE_KEY = 'LANG';
const SIGNUP_UTM_KEY = 'SIGNUP_UTM';
const PUBLIC_SEAFARER_FORM_KEY = 'PUBLIC_SEAFARER_FORM';
export const EMAIL_TO_CLAIM = 'EMAIL_TO_CLAIM';

const LANGUAGE_MAP = {
  ua: 'uk',
  en: 'en',
};

export interface PublicSeafarerFormData {
  rank: string;
  vesselType: string;
  availabilityDate: string;
}

export const getLang = (): string => {
  const lang = getFromStorage(LANGUAGE_KEY);
  const adaptedLang = LANGUAGE_MAP[lang] || 'en';

  saveToStorage(LANGUAGE_KEY, adaptedLang);

  return adaptedLang;
};

export const getUtmFromStorage = (defaultLandingPage?: string): Utm | null => {
  const signupUtm = getFromStorage(SIGNUP_UTM_KEY);

  if (!signupUtm) {
    return null;
  }

  const utm: any = {
    landing_page: signupUtm.landingPage || defaultLandingPage,
    utm_source: signupUtm.utm_source || null,
    utm_medium: signupUtm.utm_medium || null,
    utm_campaign: signupUtm.utm_campaign || null,
    utm_term: signupUtm.utm_term || null,
    utm_content: signupUtm.utm_content || null,
  };

  return utm as Utm;
};

export const removeUtmFromStorage = (): void => {
  removeFromStorage(SIGNUP_UTM_KEY);
};

export const getPublicSeafarerFormInfo = (): PublicSeafarerFormData => {
  return getFromStorage(PUBLIC_SEAFARER_FORM_KEY);
};

export const setLocalStorageFromSignupParams = (signupQueryParams: URLSearchParams): void => {
  const lang = signupQueryParams.get('lang') || getFromStorage(LANGUAGE_KEY) || 'en';
  saveToStorage(LANGUAGE_KEY, lang);

  // if there are any UTM params, the utm_source MUST be set
  if (signupQueryParams.get('utm_source')) {
    saveToStorage(SIGNUP_UTM_KEY, {
      utm_source: signupQueryParams.get('utm_source'),
      utm_medium: signupQueryParams.get('utm_medium'),
      utm_campaign: signupQueryParams.get('utm_campaign'),
      utm_term: signupQueryParams.get('utm_term'),
      utm_content: signupQueryParams.get('utm_content'),
      landingPage: signupQueryParams.get('landingPage'),
    });
  }

  const queryParamRank = signupQueryParams.get('rank');
  const queryParamVesselType = signupQueryParams.get('vesselType');
  const queryParamAvailabilityFrom = signupQueryParams.get('availabilityFrom');

  let rank;
  let vesselType;
  let availabilityDate;

  if (queryParamRank && queryParamRank !== 'OTHER') {
    if (!(queryParamRank in RANKS)) {
      console.error(`${queryParamRank} not found in RANKS`);
    } else {
      rank = queryParamRank;
    }
  }

  if (queryParamVesselType) {
    if (!(queryParamVesselType in VESSEL_TYPES)) {
      console.error(`${queryParamVesselType} not found in VESSEL_TYPES`);
    } else {
      vesselType = queryParamVesselType;
    }
  }

  if (queryParamAvailabilityFrom) {
    if (!moment(queryParamAvailabilityFrom, 'YYYY-MM-DD', true).isValid()) {
      console.error(`${queryParamAvailabilityFrom} is not a valid YYYY-MM-DD date`);
    } else {
      availabilityDate = queryParamAvailabilityFrom;
    }
  }

  if (rank || vesselType || availabilityDate) {
    saveToStorage(PUBLIC_SEAFARER_FORM_KEY, {
      rank,
      vesselType,
      availabilityDate,
    });
  }
};
