import * as Sentry from '@sentry/browser';

const sentryDsn = 'https://8c981d10f3c840b4ade3225fbfe85192@o432091.ingest.sentry.io/5504186';
const sentryEnabled = process.env.REACT_APP_SENTRY_ENABLED === 'true';

export const initializeSentry = () => {
  if (sentryEnabled) {
    Sentry.init({
      dsn: sentryDsn,
      environment: process.env.REACT_APP_ENVIRONMENT,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,

      integrations: [
        new Sentry.Replay({
          // Additional SDK configuration goes in here, for example:
          networkCaptureBodies: true,
          networkDetailAllowUrls: [window.location.origin],
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: false,
        }),
      ],
    });
  }
};

export const setSentryTag = (key, value) => {
  Sentry.configureScope((scope) => {
    scope.setTag(key, value);
  });
};
