export const VESSEL_TYPES = {
  DRY_BULK: 'Dry Bulk',
  GENERAL_CARGO: 'General cargo',
  CONTAINER_SHIP: 'Container ship',
  LNG: 'LNG',
  LPG: 'LPG',
  PRODUCT_TANKER: 'Product tanker',
  CHEMICAL_TANKER: 'Chemical tanker',
  CRUDE_OIL_TANKER: 'Crude oil tanker',
  SPECIALIZED_TANKER: 'Specialized tanker',
  OFFSHORE: 'Offshore',
  REEFER: 'Reefer',
  PASSENGER_SHIP: 'Passenger ship',
  CEMENT_CARRIER: 'Cement carrier',
  RORO: 'Ro-Ro',
  HEAVY_LIFT: 'Heavy lift',
  TUGBOAT: 'Tugboat',
  OTHER: 'Other',
};

export const preferredVesselTypes = [
  'DRY_BULK',
  'CONTAINER_SHIP',
  'PRODUCT_TANKER',
  'CRUDE_OIL_TANKER',
  'CHEMICAL_TANKER',
  'SPECIALIZED_TANKER',
  'LPG',
  'LNG',
  'TUGBOAT',
];

export const unknownVesselType = 'UNKNOWN';
