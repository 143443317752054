import React from 'react';
import { Box, Button, Container, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80,
  },
}));

const ErrorFallback = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container className={classes.root} maxWidth="lg">
      <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'} color="textPrimary">
        Something went wrong...
      </Typography>
      <Typography align="center" variant="subtitle2" color="textSecondary">
        Our vessel is facing some difficult weather.
      </Typography>
      <Box mt={6} display="flex" justifyContent="center">
        <Button color="secondary" component={RouterLink} to="/" variant="outlined">
          Go back to safety
        </Button>
      </Box>
    </Container>
  );
};

export default ErrorFallback;
