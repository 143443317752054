import logoSeafair from 'src/assets/img/seafair-logo-big.svg';
import logoSmallSeafair from 'src/assets/img/seafair-logo-small.svg';
import logoWhiteSeafair from 'src/assets/img/seafair-logo-big-white.svg';
import logoLtMarine from 'src/assets/img/lt-marine.png';
import logoLtMarineBig from 'src/assets/img/lt_marine_logo_big.svg';
import { LT_MARINE } from 'src/constants';

type BrandInfo = {
  id: string;
  label: string;
  company: string;
  email: string;
  url: string;
  logo: string;
  logoSmall: string;
  logoWhite: string;
  logoBig: string;
  primaryColor: string;
  icoPath: string;
  privacyPolicy: string;
  termsOfUse: string;
};

type Brand = 'SEAFAIR' | 'LT_MARINE';

export const BRAND_TYPES: { [key in Brand]: BrandInfo } = {
  SEAFAIR: {
    id: 'SEAFAIR',
    label: 'Seafair',
    company: 'Seafair Inc.',
    email: 'support@seafair.io',
    url: 'https://seafarer.seafair.io',
    logo: logoSeafair,
    logoSmall: logoSmallSeafair,
    logoWhite: logoWhiteSeafair,
    logoBig: logoSeafair,
    primaryColor: '#5295EF',
    icoPath: `${process.env.PUBLIC_URL}/seafair.ico`,
    privacyPolicy: 'https://storage.googleapis.com/seafair/privacy-policy.pdf',
    termsOfUse: 'https://storage.googleapis.com/seafair/site-terms-of-use.pdf',
  },
  LT_MARINE: {
    id: 'LT_MARINE',
    label: 'LT Marine',
    company: 'LT Marine',
    email: 'support@lt-marine.com',
    url: 'https://seafarer.lt-marine.com',
    logo: logoLtMarine,
    logoSmall: logoLtMarine,
    logoWhite: logoLtMarine,
    logoBig: logoLtMarineBig,
    primaryColor: '#FF7300',
    icoPath: `${process.env.PUBLIC_URL}/lt-marine.ico`,
    privacyPolicy: 'https://www.lt-marine.com/_files/ugd/2e551d_00dd3b6fe3034d03b48e7d1fc112d360.pdf',
    termsOfUse: 'https://www.lt-marine.com/_files/ugd/2e551d_4593f359ceee43f8a5fceeec1a66aad7.pdf',
  },
};

export function getBrandType(): BrandInfo;
export function getBrandType(field: keyof BrandInfo): string | null;
export function getBrandType(field: keyof BrandInfo, valueIfEmpty: string): string;

export function getBrandType(field?: keyof BrandInfo, valueIfEmpty = null): BrandInfo | string {
  const brandType = LT_MARINE ? BRAND_TYPES.LT_MARINE : BRAND_TYPES.SEAFAIR;

  if (field) {
    if (brandType[field]) {
      return brandType[field];
    }
    return valueIfEmpty;
  }
  return brandType;
}
