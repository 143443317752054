import React, { createContext, useContext, useReducer } from 'react';

import { Application } from 'src/models/application';
import { Vacancy } from 'src/models/vacancy';

export type ApplicationsState = {
  applications: Application[];
  vacancies: Vacancy[];
};

type UpdateVacanciesAction = {
  type: 'UPDATE_VACANCIES';
  payload: {
    vacancies: Vacancy[];
  };
};

type UpdateApplicationsAction = {
  type: 'UPDATE_APPLICATIONS';
  payload: {
    applications: Application[];
  };
};

type ApplicationAction = UpdateVacanciesAction | UpdateApplicationsAction;

export type Dispatch = (action: ApplicationAction) => void;

const INIITIAL_APPLICATIONS_STATE: ApplicationsState = {
  applications: [],
  vacancies: [],
};

const ApplicationsContext = createContext<{ state: ApplicationsState; dispatch: Dispatch | undefined }>({
  state: INIITIAL_APPLICATIONS_STATE,
  dispatch: undefined,
});

export const useApplications = () => useContext(ApplicationsContext);

export const applicationsReducer = (state: ApplicationsState, action: ApplicationAction) => {
  const { type } = action;

  switch (type) {
    case 'UPDATE_APPLICATIONS': {
      return {
        ...state,
        applications: (action as UpdateApplicationsAction).payload?.applications,
      };
    }
    case 'UPDATE_VACANCIES': {
      return {
        ...state,
        vacancies: (action as UpdateVacanciesAction).payload?.vacancies,
      };
    }
    default:
      return state;
  }
};

export const ApplicationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(applicationsReducer, INIITIAL_APPLICATIONS_STATE);

  const value = { state, dispatch };
  return <ApplicationsContext.Provider value={value}>{children}</ApplicationsContext.Provider>;
};
