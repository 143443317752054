import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { AppBar, Toolbar, makeStyles } from '@material-ui/core';
import LogoWhite from 'src/components/LogoWhite';
import type { Theme } from 'src/theme';

interface LogoTopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
  },
  toolbar: {
    minHeight: 75,
    justifyContent: 'center',
  },
}));

const LogoTopBar: FC<LogoTopBarProps> = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <LogoWhite />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

export default LogoTopBar;
