import TagManager from 'react-gtm-module';
import { RANKS, top4, junior4 } from '@seafair/frontend.constants';
import { preferredVesselTypes } from 'src/constants/vesselTypes';
import { LT_MARINE } from 'src/constants';
import { Rank } from '@seafair/frontend.types';

const seafairGtmId = 'GTM-PF22CPK';
const ltMarineGtmId = 'GTM-NX4JNKJ';
const gtmEnabled = process.env.REACT_APP_GTM_ENABLED === 'true';

const seafarerRegistrationEvent = 'seafarer-registration';
const top4RegistrationEvent = 'top4-registration';
const etoRegistrationEvent = 'eto-registration';
const junior4registrationEvent = 'junior4-registration';
const preferredVesselRegistrationEvent = 'preferred-vessel-registration';
const top4EtoPrefVesselRegistrationEvent = 'top4-eto-preferred-vessel-registration';
const relevantRegistrationEvent2023Q2 = '2023-q2-relevant-registration';
const pageViewEvent = 'customPageViewEvent';

const allRanksCompleteFlowEvent = 'all-complete-flow';
const top5CompleteFlowEvent = 'top-5-complete-flow';
const ratingsCompleteFlowEvent = 'ratings-complete-flow';
const etoCompleteFlowEvent = 'eto-complete-flow';
const seniorDeckCompleteFlowEvent = 'senior-deck-complete-flow';
const juniorDeckCompleteFlowEvent = 'junior-deck-complete-flow';
const seniorEngCompleteFlowEvent = 'senior-eng-complete-flow';
const juniorEngCompleteFlowEvent = 'junior-eng-complete-flow';

const FACEBOOK_UTM_SOURCE = 'facebook';

const initialize = () => {
  if (gtmEnabled) {
    TagManager.initialize({ gtmId: LT_MARINE ? ltMarineGtmId : seafairGtmId });
  }
};

// [BA] As of 2023-06-23 we are ONLY triggering events if the utmSource is facebook
// https://seafairworkspace.slack.com/archives/C04U8PH79R6/p1686238456639349?thread_ts=1685960473.206729&cid=C04U8PH79R6
const shouldTriggerRegistrationEvent = (utmSource: string): boolean => {
  return utmSource === FACEBOOK_UTM_SOURCE;
};

const triggerUserRegistrationEvent = () => {
  if (gtmEnabled) {
    TagManager.dataLayer({ dataLayer: { event: seafarerRegistrationEvent } });
  }
};

const triggerTop4RegistrationEvent = () => {
  if (gtmEnabled) {
    TagManager.dataLayer({ dataLayer: { event: top4RegistrationEvent } });
  }
};

const triggerEtoRegistrationEvent = () => {
  if (gtmEnabled) {
    TagManager.dataLayer({ dataLayer: { event: etoRegistrationEvent } });
  }
};

const triggerJunior4RegistrationEvent = () => {
  if (gtmEnabled) {
    TagManager.dataLayer({ dataLayer: { event: junior4registrationEvent } });
  }
};

const triggerRelevantRegistrationEvent = () => {
  if (gtmEnabled) {
    TagManager.dataLayer({ dataLayer: { event: relevantRegistrationEvent2023Q2 } });
  }
};

const triggerPreferredVesselRegistrationEvent = () => {
  if (gtmEnabled) {
    TagManager.dataLayer({ dataLayer: { event: preferredVesselRegistrationEvent } });
  }
};

const triggerTop4EtoPreferredVesselRegistrationEvent = () => {
  if (gtmEnabled) {
    TagManager.dataLayer({ dataLayer: { event: top4EtoPrefVesselRegistrationEvent } });
  }
};

const triggerAllRanksCompleteFlowEvent = () => {
  if (gtmEnabled) {
    TagManager.dataLayer({ dataLayer: { event: allRanksCompleteFlowEvent } });
  }
};

const triggerTop5CompleteFlowEvent = () => {
  if (gtmEnabled) {
    TagManager.dataLayer({ dataLayer: { event: top5CompleteFlowEvent } });
  }
};

const triggerRatingsCompleteFlowEvent = () => {
  if (gtmEnabled) {
    TagManager.dataLayer({ dataLayer: { event: ratingsCompleteFlowEvent } });
  }
};

const triggerEtoCompleteFlowEvent = () => {
  if (gtmEnabled) {
    TagManager.dataLayer({ dataLayer: { event: etoCompleteFlowEvent } });
  }
};

const triggerSeniorDeckCompleteFlowEvent = () => {
  if (gtmEnabled) {
    TagManager.dataLayer({ dataLayer: { event: seniorDeckCompleteFlowEvent } });
  }
};

const triggerJuniorDeckCompleteFlowEvent = () => {
  if (gtmEnabled) {
    TagManager.dataLayer({ dataLayer: { event: juniorDeckCompleteFlowEvent } });
  }
};

const triggerSeniorEngCompleteFlowEvent = () => {
  if (gtmEnabled) {
    TagManager.dataLayer({ dataLayer: { event: seniorEngCompleteFlowEvent } });
  }
};

const triggerJuniorEngCompleteFlowEvent = () => {
  if (gtmEnabled) {
    TagManager.dataLayer({ dataLayer: { event: juniorEngCompleteFlowEvent } });
  }
};

export const triggerCompleteFlowEvent = (utmSource: string, rank: Rank) => {
  if (!shouldTriggerRegistrationEvent(utmSource)) {
    return;
  }
  switch (rank) {
    case 'MASTER':
    case 'CHIEF_OFFICER':
      triggerSeniorDeckCompleteFlowEvent();
      triggerTop5CompleteFlowEvent();
      triggerAllRanksCompleteFlowEvent();
      break;
    case 'SECOND_OFFICER':
    case 'THIRD_OFFICER':
      triggerJuniorDeckCompleteFlowEvent();
      triggerAllRanksCompleteFlowEvent();
      break;
    case 'CHIEF_ENGINEER':
    case 'SECOND_ENGINEER':
      triggerSeniorEngCompleteFlowEvent();
      triggerTop5CompleteFlowEvent();
      triggerAllRanksCompleteFlowEvent();
      break;
    case 'THIRD_ENGINEER':
    case 'FOURTH_ENGINEER':
      triggerJuniorEngCompleteFlowEvent();
      triggerAllRanksCompleteFlowEvent();
      break;
    case 'ETO':
      triggerEtoCompleteFlowEvent();
      triggerTop5CompleteFlowEvent();
      triggerAllRanksCompleteFlowEvent();
      break;
    default:
      triggerRatingsCompleteFlowEvent();
      triggerAllRanksCompleteFlowEvent();
  }
};

export const triggerRankAndVesselSpecificEvents = (
  utmSource: string | null,
  rank: string,
  vesselType: string,
  initialRank?: string,
  initialVesselType?: string,
) => {
  if (!shouldTriggerRegistrationEvent(utmSource)) {
    return;
  }
  const rankChanged = rank && rank !== initialRank;
  const vesselTypeChanged = vesselType && vesselType !== initialVesselType;

  if (rankChanged || vesselTypeChanged) {
    const isTop4 = top4.includes(rank as Rank);
    const isEto = rank === RANKS.ETO.id;
    const isJunior4 = junior4.includes(rank as Rank);
    const prefVessel = preferredVesselTypes.includes(vesselType);

    if (rankChanged) {
      if (isTop4) {
        triggerTop4RegistrationEvent();
      } else if (isEto) {
        triggerEtoRegistrationEvent();
      } else if (isJunior4) {
        triggerJunior4RegistrationEvent();
      }
    }

    if (vesselTypeChanged && prefVessel) {
      triggerPreferredVesselRegistrationEvent();
    }

    if (rankChanged || vesselTypeChanged) {
      if (prefVessel) {
        if (isTop4 || isEto) {
          triggerTop4EtoPreferredVesselRegistrationEvent();
          triggerRelevantRegistrationEvent();
        }
        if (isJunior4) {
          triggerRelevantRegistrationEvent();
        }
      }
    }
  }
};

export const triggerPageView = (title: string) => {
  if (gtmEnabled) {
    TagManager.dataLayer({ dataLayer: { event: pageViewEvent, page: { title } } });
  }
};

const gtm = {
  initialize,
  triggerUserRegistrationEvent,
  triggerRankAndVesselSpecificEvents,
  triggerPageView,
  triggerCompleteFlowEvent,
};

export default gtm;
