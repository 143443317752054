/* eslint no-extend-native: 0 */
export {};

declare global {
  interface Date {
    addDays(days: number): Date;
    addMonths(months: number): Date;
    clone(): Date;
    diffInMonths(since: Date): number; // get diff in months from a given 'since' date
    isAnotherMonth(date: Date): boolean;
    isSameDate(date: Date): boolean;
    isToday(): boolean;
    isWeekend(): boolean;
  }
}

Date.prototype.addDays = function addDays(days: number): Date {
  this.setDate(this.getDate() + days);
  return this;
};

Date.prototype.addMonths = function addMonths(months: number): Date {
  const d = this.getDate();
  this.setMonth(this.getMonth() + months);
  if (this.getDate() !== d) {
    this.setDate(0);
  }
  return this;
};

Date.prototype.clone = function clone(): Date {
  return new Date(+this);
};

Date.prototype.diffInMonths = function diffInMonths(since: Date): number {
  let months;
  months = (this.getFullYear() - since.getFullYear()) * 12;
  months -= since.getMonth();
  months += this.getMonth();
  return months <= 0 ? 0 : months;
};

Date.prototype.isAnotherMonth = function isAnotherMonth(date: Date): boolean {
  return date && this.getMonth() !== date.getMonth();
};

Date.prototype.isToday = function isToday(): boolean {
  const today = new Date();
  return this.isSameDate(today);
};

Date.prototype.isSameDate = function isSameDate(date: Date): boolean {
  return (
    date &&
    this.getFullYear() === date.getFullYear() &&
    this.getMonth() === date.getMonth() &&
    this.getDate() === date.getDate()
  );
};

Date.prototype.isWeekend = function isWeekend(): boolean {
  return this.getDay() === 0 || this.getDay() === 6;
};
